import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/tolmin-2030/tolmin_img1.png'
import img2 from '../../assets/images/tolmin-2030/tolmin_img2.png'
import img3 from '../../assets/images/tolmin-2030/tolmin_img3.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { theme, colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionCounterGrid,
  SectionFullImage,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import {
  HeadlineM,
  TitleL,
  TitleM,
  ParagraphL,
  ParagraphM,
  ParagraphS,
} from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'Grafično oblikovanje',
    'Logotip',
    'Oglaševanje na družbenih omrežjih',
    'Spletne strani',
    'Grafično oblikovanje',
    'Logotip',
    'Oglaševanje na družbenih omrežjih',
    'Spletne strani',
    'Grafično oblikovanje',
    'Logotip',
    'Oglaševanje na družbenih omrežjih',
    'Spletne strani',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | Tolmin 2030"
        description="Projekt smo začeli z oblikovanjem celostne grafične podobe, za potrebe projekta smo vzpostavili spletno stran, posneli promocijski video, oblikovali dokument in o vsemu poročali na družbenih omrežjih."
        image={img1}
      />
      <Header
        logoBackground={colors.purple}
        logoBorder={colors.purple}
        linkText={colors.purple}
        menuLines={colors.purple}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="Tolmin 2030" />
          </div>
          <Square background={colors.red} className="title">
            <ParagraphM color={colors.white}>Občina Tolmin</ParagraphM>
            <HeadlineM color={colors.purple}>Tolmin 2030</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.purple}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Poskrbeti za celostno podobo in komunikacijo tekom nastajanja razvojnega programa
              Občine Tolmin za obdobje 2021 - 2030.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Projekt smo začeli z oblikovanjem celostne grafične podobe, za potrebe projekta smo
              vzpostavili spletno stran, posneli promocijski video, oblikovali dokument in o vsemu
              poročali na družbenih omrežjih.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-top-padding reduce-bottom-padding">
            <Content>
              {/* Example Title */}
              <h2>Grafična podoba</h2>

              {/* Example Paragraph */}
              <p>
                Dokument je namenjen predvsem digitalni objavi, zato je pdf interaktiven in se je po
                njem lahko premikati. Tekst in tabele so dopolnjene z grafi za lažje razumevanje
                vsebine.
              </p>
            </Content>
          </SectionRichText>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img2} alt="Tolmin 2030" />
          </SectionFullImage>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img3} alt="Tolmin 2030" />
          </SectionFullImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content>
              {/* Example Title */}
              <h2>Promocijski video</h2>

              {/* Example Paragraph */}
              <p>
                V promocijskem videu smo občane iz občine Tolmin vprašali s katero besedo bi opisali
                občino Tolmin leta 2030. Kompilacijo kratkih odgovorov smo združili v kratek
                promocijski video.
              </p>
              <p>Snemanje in montaža: Andrej Rutar</p>

              {/* Example Video */}
              <iframe
                src="https://www.youtube.com/embed/ik8l561iYEw"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>

              {/* Example Title */}
              <h2>Spletna stran</h2>

              {/* Example Paragraph */}
              <p>
                Za projekt smo izdelali enostavno spletno stran, na katero smo najprej objavili
                rezultate ankete, ki jo je izvedla Občina Tolmin za potrebe priprav razvojnega
                programa.
              </p>
              <p>
                V drugi fazi je bila spletna stran namenjena prebiranju strateškega dokumenta,
                medtem ko je v javni obravnavi. Ker so tovrstni dokumenti običajno v javno obravnavo
                dani v obliki pdf dokumenta, jih je težko prebirati na mobilnih napravah. Zato smo
                obliko prilagodili za mobilne naprave.
              </p>
            </Content>
          </SectionRichText>

          {/* CounterGrid Section - Conditionally visible */}
          <SectionCounterGrid>
            <div className="group">
              <Square background={colors.purple} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  36000+
                </TitleL>
                <ParagraphL color={colors.white}>ogledov spletne strani</ParagraphL>
              </Square>
              <Square background={colors.red} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  42
                </TitleL>
                <ParagraphL color={colors.white}>ogledov promocijskega videa</ParagraphL>
              </Square>
            </div>
            <div className="group reverse">
              <Square background={colors.purple} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  36000+
                </TitleL>
                <ParagraphL color={colors.white}>prenosov pdf dokumenta</ParagraphL>
              </Square>
              <Square background={colors.red} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  42
                </TitleL>
                <ParagraphL color={colors.white}>
                  komentarjev dokumenta v času javne obravnave
                </ParagraphL>
              </Square>
            </div>
          </SectionCounterGrid>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content>
              {/* Example Title */}
              <h2>Družbena omrežja</h2>

              {/* Example Paragraph */}
              <p>
                O poteku nastajanja razvojnega programa smo redno obveščali preko družbenega omrežja
                Facebook.
              </p>
            </Content>
          </SectionRichText>
        </div>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          <Link to="/project/atrium-interieri">
            <ParagraphL>← Prejšnji projekt </ParagraphL>
          </Link>
          {/* <Link to="/project/next-project"> */}
          <ParagraphL className="disabled">Naslednji projekt →</ParagraphL>
          {/* </Link> */}
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
